import "./socialicons.scss";
import fb from "../../assets/images/socialicon/fb.png";
import insta from "../../assets/images/socialicon/insta.png";
import indn from "../../assets/images/socialicon/in.png";
// import yt from "../../assets/images/socialicon/yt.png";
// import tw from "../../assets/images/socialicon/tw.png";
import gh from "../../assets/images/socialicon/gh.png";

const Socialicons = () => {
  return (
    // {/* <!--------- Social Icon ----------> */}

    <div className="social-icon">
      <div className="icon">
        <a
          href="https://www.facebook.com/fbbishal"
          target="_blank"
          rel="noreferrer"
        >
          <img className="facebook" src={fb} alt="facebook" />
        </a>
      </div>
      <div className="icon">
        <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
          <img className="insta" src={insta} alt="instagram" />
        </a>
      </div>
      <div className="icon">
        <a
          href="https://www.linkedin.com/in/bishal-khadka-1aa09220a"
          target="_blank"
          rel="noreferrer"
        >
          <img className="linkedin" src={indn} alt="linkedin" />
        </a>
      </div>
      {/* <div className="icon">
        <a
          href="https://www.facebook.com/fbbishal"
          target="_blank"
          rel="noreferrer"
        >
          <img className="twitter" src={tw} alt="twitter" />
        </a>
      </div> */}
      {/* <div className="icon">
        <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
          <img className="youtube" src={yt} alt="" />
        </a>
      </div> */}
      <div className="icon">
        <a href="https://github.com/ghbishal" target="_blank" rel="noreferrer">
          <img className="github" src={gh} alt="" />
        </a>
      </div>
    </div>
  );
};

export default Socialicons;
