import Aboutus from "./components/aboutus/Aboutus";
import Contact from "./components/contact/Contact";
import Home from "./components/home/Home";
import Navbar from "./components/navbar/Navbar";
import Services from "./components/services/Services";
import Works from "./components/works/Works";
// import PreLoader from "./layouts/PreLoader";
import Progressbar from "./layouts/progressbar/Progressbar";

function App() {
  return (
    <div className="App">
      {/* <PreLoader /> */}
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Bishal | Front End Developer</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="Personal portfolio website" />
      </Helmet> */}

      <Navbar />
      <Home />
      <Aboutus />
      <Services />
      <Works />
      <Contact />
      <Progressbar />
    </div>
  );
}

export default App;
