import "./navbar.scss";
import logo from "../../assets/images/Bishal_white.png";
import {useState, useEffect} from "react";

const Navbar = () => {
  const [isActive, setActive] = useState("false");
  const [scroll, setScroll] = useState(false);

  const handelToggle = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 30);
    });
  }, []);

  return (
    <section id="navbar" className={scroll ? "scrolled" : null}>
      <nav className="container-nav">
        <a href="/">
          {/* <!---------------- Navbar Logo-----------------> */}
          <img className="img-fluid" src={logo} alt="logo" />
        </a>

        <div className={isActive ? "navbar " : "navbar navbar-active"}>
          <ul
            className={
              isActive ? "nav-content" : "nav-content navContent-active"
            }
          >
            <li>
              <a
                href="#home"
                onClick={() => {
                  setActive(!isActive);
                }}
                className="nav-links"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                onClick={() => {
                  setActive(!isActive);
                }}
                className="nav-links"
              >
                About
              </a>
            </li>

            <li>
              <a
                href="#services"
                onClick={() => {
                  setActive(!isActive);
                }}
                className="nav-links"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#resume"
                onClick={() => {
                  setActive(!isActive);
                }}
                className="nav-links"
              >
                Resume
              </a>
            </li>
            <li>
              <a
                href="#works"
                onClick={() => {
                  setActive(!isActive);
                }}
                className="nav-links"
              >
                works
              </a>
            </li>
            <li>
              <a
                href="#contact"
                onClick={() => {
                  setActive(!isActive);
                }}
                className="nav-links"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>

        <div className="menu" onClick={handelToggle}>
          <div className={isActive ? "row1" : "row1 animate1"}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className={isActive ? "row2" : "row2 animate2"}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className={isActive ? "row3" : "row3 animate3"}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
    </section>

    // <section id="navbar" className={scroll ? "scrolled" : null}>
    //   <nav className="container-nav">
    //     <a href="/">
    //       {/* <!---------------- Navbar Logo-----------------> */}
    //       <img className="img-fluid" src={logo} alt="logo" />
    //     </a>

    //     <div className={isActive ? "navbar " : "navbar navbar-active"}>
    //       <ul
    //         className={
    //           isActive ? "nav-content" : "nav-content navContent-active"
    //         }
    //       >
    //         <li>
    //           <a
    //             href="#home"
    //             onClick={() => {
    //               setActiveNav("#home");
    //               setActive(!isActive);
    //             }}
    //             className={
    //               activeNav === "#home" ? "nav-links active" : "nav-links"
    //             }
    //           >
    //             Home
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="#about"
    //             onClick={() => {
    //               setActiveNav("#aboutme");
    //               setActive(!isActive);
    //             }}
    //             className={
    //               activeNav === "#aboutme" ? "nav-links active" : "nav-links"
    //             }
    //           >
    //             About
    //           </a>
    //         </li>
    //         {/* <li>
    //           <a
    //             href="#resume"
    //             onClick={() => {
    //               setActiveNav("#resume");
    //               setActive(!isActive);
    //             }}
    //             className={
    //               activeNav === "#resume" ? "nav-links active" : "nav-links"
    //             }
    //           >
    //             Service
    //           </a>
    //         </li> */}
    //         <li>
    //           <a
    //             href="#services"
    //             onClick={() => {
    //               setActiveNav("#skills");
    //               setActive(!isActive);
    //             }}
    //             className={
    //               activeNav === "#skills" ? "nav-links active" : "nav-links"
    //             }
    //           >
    //             Services
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="#portfolio"
    //             onClick={() => {
    //               setActiveNav("#portfolio");
    //               setActive(!isActive);
    //             }}
    //             className={
    //               activeNav === "#portfolio" ? "nav-links active" : "nav-links"
    //             }
    //           >
    //             Portfolio
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="#contactme"
    //             onClick={() => {
    //               setActiveNav("#contactme");
    //               setActive(!isActive);
    //             }}
    //             className={
    //               activeNav === "#contactme" ? "nav-links active" : "nav-links"
    //             }
    //           >
    //             Contact
    //           </a>
    //         </li>
    //       </ul>
    //     </div>

    //     <div className="menu" onClick={handelToggle}>
    //       <div className={isActive ? "row1" : "row1 animate1"}>
    //         <span></span>
    //         <span></span>
    //         <span></span>
    //       </div>

    //       <div className={isActive ? "row2" : "row2 animate2"}>
    //         <span></span>
    //         <span></span>
    //         <span></span>
    //       </div>

    //       <div className={isActive ? "row3" : "row3 animate3"}>
    //         <span></span>
    //         <span></span>
    //         <span></span>
    //       </div>
    //     </div>
    //   </nav>
    // </section>
  );
};

export default Navbar;
