import "./aboutus.scss";
import PPIMG from "../../assets/images/ppmain.jpg";
import Socialicons from "../socialicons/Socialicons";
import resume from "../../assets/resumepdf/resume.pdf";

const Aboutus = () => {
  //Auto Date Change website
  var DOI = "1999/11/23";
  function getAge(Doi) {
    const today = new Date();
    const birthDay = new Date(Doi);
    var age = today.getFullYear() - birthDay.getFullYear();
    const month = today.getMonth() - birthDay.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDay.getDate())) {
      age = age - 1;
    }

    return age;
  }
  var age = getAge(DOI);

  return (
    <section className="bishal_section" id="about">
      <section className="container-aboutme">
        <div className="aboutme">
          <div className="aboutme-head">
            <h2>Get to know me</h2>
            <h1>About Me</h1>

            <div className="line">
              <div className="lines"></div>
            </div>
          </div>

          <div className="more-aboutme" id="intro">
            <div className="aboutmeimg">
              <img src={PPIMG} alt="ppimg" />
            </div>

            <div className="introduction">
              <h2>Who am i?</h2>
              <h1>
                Bishal Khadka, a UI/UX Designer, Photographer and Web Developer
              </h1>
              <p>
                I am a graduating senior studying computer science at Temple
                University in Japan. I have a minimum of 2 years of expertise
                creating high-quality graphic designs and websites. Assisting in
                transforming abstract concepts into tangible, usable goods.
                <br />
                I've been teaching myself Web development and looking for work
                in this field.
              </p>
              <br />
              <div className="hr"></div>
              <br />

              <div className="my-info">
                <div className="row-1">
                  <div className="name">
                    <span>Name:</span>
                    <p>Bishal Khadka</p>
                  </div>
                  <div className="email">
                    <span>Email:</span>
                    <p>
                      <a href="mailto:emma@example.com">
                        bishalk2056@gmail.com
                      </a>
                    </p>
                  </div>
                </div>

                <div className="row-2">
                  <div className="age">
                    <span>Age:</span>
                    <p>{age}</p>
                  </div>
                  <div className="nationality">
                    <span>location:</span>
                    <p>Japan</p>
                  </div>
                </div>

                <div className="download-resume">
                  <div className="download-content">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14.29,17.29,13,18.59V13a1,1,0,0,0-2,0v5.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l3,3a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l3-3a1,1,0,0,0-1.42-1.42ZM18.42,6.22A7,7,0,0,0,5.06,8.11,4,4,0,0,0,6,16a1,1,0,0,0,0-2,2,2,0,0,1,0-4A1,1,0,0,0,7,9a5,5,0,0,1,9.73-1.61,1,1,0,0,0,.78.67,3,3,0,0,1,.24,5.84,1,1,0,1,0,.5,1.94,5,5,0,0,0,.17-9.62Z" />
                    </svg>

                    {/* <!------------------------------- Resume Download Page---------------------------> */}

                    <a href={resume} target="_blank" rel="noreferrer">
                      Resume
                    </a>
                  </div>

                  <div className="hr2"></div>

                  {/* <!--------- Social Icon ----------> */}

                  <Socialicons />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Aboutus;
