import "./contact.scss";
import Socialicons from "../socialicons/Socialicons";
import vectorleft from "../../assets/svg/vectorleft.svg";
import vectorrigt from "../../assets/svg/vectorrigt.svg";

import {useRef, useState} from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  // const nameRef = useRef();
  // const emailRef = useRef();
  // const subjectRef = useRef();
  // const messageRef = useRef();

  // const handleChange = (event) => {
  //   setName(event.target.value);
  //   setEmail(event.target.value);
  //   setSubject(event.target.value);
  //   setMessage(event.target.value);
  // };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_s0dqo5k",
        "template_zzbxy7y",
        form.current,
        "VM9x5CufUB2MLxG1C"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setName(" ");
    setEmail(" ");
    setSubject(" ");
    setMessage(" ");

    e.target.reset();
  };
  return (
    <section id="contact">
      <div className="contact">
        <div className="contact-header">
          <h2>I want to hear from you</h2>
          <h1>contact me</h1>

          <div className="line">
            <div className="lines"></div>
          </div>
        </div>

        <div className="contact-container">
          <div className="left">
            {/* <span>
              <a href="mailto:infomaya.tonni@gmail.com">
                infomaya.tonni@gmail.com
              </a>
              <img className="svg" src="img/svg/vector3.svg" alt="" />
            </span> */}

            <div className="form">
              <form className="contact-form" ref={form} onSubmit={sendEmail}>
                <div className="form-header">
                  <input
                    className="form-input"
                    type="text"
                    id="contact-name"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder="Name"
                    required
                  />
                  <input
                    className="form-input"
                    type="email"
                    id="contact-email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email"
                    required
                  />
                </div>

                <div className="form-header">
                  <input
                    className="form-input"
                    type="text"
                    id="contact-subject"
                    name="subject"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-header">
                  <textarea
                    placeholder="Write your message"
                    className="form-input"
                    id="contact-message"
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    required
                  ></textarea>
                </div>
                <div className="submit-button">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>

            {/* <div className="form">
              <form className="contact-form" ref={form} onSubmit={sendEmail}>
                <div className="form-header">
                  <input
                    ref={nameRef}
                    className="form-input"
                    type="text"
                    id="contact-name"
                    name="name"
                    placeholder="Name"
                    required
                  />
                  <input
                    ref={emailRef}
                    className="form-input"
                    type="email"
                    id="contact-email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>

                <div className="form-header">
                  <input
                    ref={subjectRef}
                    className="form-input"
                    type="text"
                    id="contact-subject"
                    name="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-header">
                  <textarea
                    ref={messageRef}
                    placeholder="Write your message"
                    className="form-input"
                    id="contact-message"
                    name="message"
                    required
                  ></textarea>
                </div>
                <div className="submit-button">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div> */}

            {/* <div className="form">
              <form className="contact-form" ref={form} onSubmit={sendEmail}>
                <div className="form-header">
                  <input
                    className="form-input"
                    type="text"
                    id="contact-name"
                    name="name"
                    placeholder="Name"
                    required
                  />
                  <input
                    className="form-input"
                    type="email"
                    id="contact-email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>

                <div className="form-header">
                  <input
                    className="form-input"
                    type="text"
                    id="contact-subject"
                    name="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-header">
                  <textarea
                    placeholder="Write your message"
                    className="form-input"
                    id="contact-message"
                    name="message"
                    required
                  ></textarea>
                </div>
                <div className="submit-button">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div> */}
          </div>
          <div className="right">
            <div className="copyright">
              <Socialicons />
              <p>
                © {new Date().getFullYear()} by{" "}
                <a href="https://bishaal.com/" target="_blank" rel="noreferrer">
                  bishal.
                </a>{" "}
                All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <span className="shape">
        <img className="svg" src={vectorleft} alt="left" />
      </span>
      <span className="shape2">
        <img className="svg" src={vectorrigt} alt="right" />
      </span>
    </section>
  );
};

export default Contact;
