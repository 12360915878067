import "./portfolio.scss";
import link from "../../../assets/svg/link.png";
import works from "../../../data/works";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <div className="portfolio-container">
        <div className="portfolios">
          {works.map(({id, type, name, links, image}) => {
            return (
              <div class="works" key={id}>
                <div class="card">
                  <div className="works-info">
                    <a href={links}>{name}</a>
                    <h3>{type}</h3>
                  </div>
                  <a
                    href={links}
                    className="img-text"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={image} alt={name} className="bg-image" />
                    <div className="img">
                      <a href={links} target="_blank" rel="noreferrer">
                        <img src={link} alt="link" />
                      </a>
                    </div>
                  </a>
                </div>
              </div>
            );
          })}
          {/* <div class="works">
            <div class="card">
              <div className="works-info">
                <a href="/">Web Application</a>
                <h3>Ds Dinning</h3>
              </div>
              <a href="/" className="img-text">
                <img src={fol} script="blur(50px)" alt="dsDinning" />
                <div className="img">
                  <a href="/">
                    <img src={link} alt="links" />
                  </a>
                </div>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
