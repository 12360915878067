import {useEffect} from "react";
import {scrollTop} from "../../utilities";
import "./progressbar.scss";

const Progressbar = () => {
  useEffect(() => {
    window.addEventListener("scroll", scrollTop);
  }, []);

  const onClick = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  };
  return (
    <div className="progressbar">
      <a
        href="#home"
        id="scroll-top"
        onClick={() => onClick()}
        rel="noreferrer"
      >
        <span className="text" style={{bottom: "150px"}}>
          To Top
        </span>
      </a>
      <span className="line" />
    </div>
  );
};
export default Progressbar;
