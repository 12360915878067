import "./home.scss";
import vdo from "../../assets/videos/bgvdo01.mp4";
import Socialicons from "../socialicons/Socialicons";

const Home = () => {
  return (
    <section className="bishal_section" id="home">
      <video className="background-video" autoPlay loop muted playsInline>
        <source src={vdo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <main className="container-landing">
        <div className="container-main">
          {/* <!--------- Slogin Big ----------> */}

          <div className="slogan">
            <h1>
              <span>Imagination</span> is more <br />
              important than <br />
              knowledge
            </h1>
            {/* <!--------- Slogin Small ----------> */}

            <div className="sloganV2">
              <div className="line1"></div>
              <p className="line1">
                Together we the people can achieve more than <br />
                any single person could ever do alone.
              </p>
            </div>

            {/* <!--------- Social Icon ----------> */}

            <Socialicons />

            <div className="button">
              <a href="#about">See Who i'm</a>
            </div>
          </div>

          {/* <!--------- Game ----------> */}
          {/* 
          <div className="game">
            <div className="container-game">
              <h1 className="game-title">
                Tic <span>Tac</span> Toe
              </h1>

              <div className="game-action">
                <div className="turns">X Turn</div>
                <div className="reset">Reset</div>
              </div>

              <div className="game-content">
                <div className="game-box tl"></div>
                <div className="game-box tm"></div>
                <div className="game-box tr"></div>
                <div className="game-box ml"></div>
                <div className="game-box mm"></div>
                <div className="game-box mr"></div>
                <div className="game-box bl"></div>
                <div className="game-box bm"></div>
                <div className="game-box br"></div>
              </div>
            </div>
          </div> */}
        </div>
      </main>
    </section>
  );
};

export default Home;
