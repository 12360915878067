import Portfolio from "../pages/portfolio/Portfolio";
import "./works.scss";

const Works = () => {
  return (
    <section className="bishal_section" id="works">
      <div className="container-works">
        <div className="works">
          <div className="works-header">
            <h2>My works</h2>
            <h1>Portfolio</h1>
            <div className="line">
              <div className="lines"></div>
            </div>
          </div>

          {/* <!---------------------- My works ---------------------------> */}
          <Portfolio />
        </div>
      </div>
    </section>
  );
};

export default Works;
