import "./servicecard.scss";
import services from "../../../data/Services";

const Servicecard = () => {
  return (
    <div className="services-card">
      <div className="services-card-container">
        {services.map(({id, num, title, svgimg, des}) => {
          return (
            <div className="card" key={id}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <div className="content">
                <h2>{num}</h2>
                <img src={svgimg} alt={title} />
                <h3>{title}</h3>
                <p>{des}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Servicecard;
