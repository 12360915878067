import dsdining from "../assets/portfolio/dsdining.jpg";
import tictactoe from "../assets/portfolio/tictactoe.jpg";
import priceplanner from "../assets/portfolio/priceplanner.jpg";
import candy from "../assets/portfolio/candy.jpg";
import portfolio from "../assets/portfolio/portfolio.jpg";
import nanhouse from "../assets/portfolio/nanhouse.jpg";
import loginsignup from "../assets/portfolio/loginsignup.jpg";

const works = [
  {
    id: 1,
    type: "Web Application",
    name: "Ds Dining",
    links: "https://dsdining.shop/",
    image: dsdining,
  },

  {
    id: 2,
    type: "Game",
    name: "Tic Tac Toe",
    links: "https://ghbishal.github.io/tictactoe.github.io/",
    image: tictactoe,
  },

  {
    id: 3,
    type: "Web Application",
    name: "price planner",
    links: "https://ghbishal.github.io/priceplanner/",
    image: priceplanner,
  },

  {
    id: 4,
    type: "Web Application",
    name: "Museum of Candy",
    links: "https://ghbishal.github.io/candyproject/",
    image: candy,
  },
  {
    id: 5,
    type: "Web Application",
    name: "Namina Portfolio",
    links: "https://ghbishal.github.io/namiportfolio/",
    image: portfolio,
  },

  {
    id: 6,
    type: "Web Application",
    name: "Nanhouse (in progress)",
    links: "https://ghbishal.github.io/nanhouse/",
    image: nanhouse,
  },

  {
    id: 7,
    type: "Web Application",
    name: "Login Signup",
    links: "https://ghbishal.github.io/userloginsignup/",
    image: loginsignup,
  },
];

export default works;
