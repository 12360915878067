import "./resume.scss";
import EDUC from "../../../assets/svg/education.png";
import WORK from "../../../assets/svg/work.png";
import {education, experience} from "../../../data/education";

const Resume = () => {
  return (
    <section id="resume">
      <div className="container-1440">
        <div className="services-header">
          <h2>My Resume</h2>
          <h1>Resume</h1>
          <div className="line">
            <div className="lines"></div>
          </div>
        </div>

        <div className="resume-container">
          <div class="resume-body">
            <div class="resume-education">
              <div className="education-header">
                <img src={EDUC} alt="education" />
                <h1>Education</h1>
              </div>

              {education.map(({id, year, name, place}) => {
                return (
                  <div class="education" key={id}>
                    <h3>{year}</h3>
                    <h2>{name}</h2>
                    <p>{place}</p>
                  </div>
                );
              })}
            </div>

            <div class="resume-education">
              <div className="education-header">
                <img src={WORK} alt="education" />

                <h1>Experience</h1>
              </div>

              {experience.map(({id, year, name, place}) => {
                return (
                  <div class="education" key={id}>
                    <h3>{year}</h3>
                    <h2>{name}</h2>
                    <p>{place}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
