// SVG Import for WorkSkill section Start//
import REACT from "../assets/svg/react.png";
import JavaScript from "../assets/svg/javascript.png";
import css from "../assets/svg/css.png";
import html from "../assets/svg/html.png";
import python from "../assets/svg/python.png";
import photoshop from "../assets/svg/photoshop.png";
import illustrator from "../assets/svg/illustrator.png";
import powerbi from "../assets/svg/powerbi.png";

// SVG Import for WorkSkill section End//

export const education = [
  {
    id: 1,
    year: "2018 ~ Present",
    name: "Bachelor's Degree",
    place: "Temple University Japan",
  },
  {
    id: 2,
    year: "2021/04 ~ 2021/08",
    name: "Google IT support",
    place: "Google Career Certificate",
  },
  {
    id: 3,
    year: "2020 ~ 2021",
    name: "Full-Stack WebDeveloper",
    place: "Udemy",
  },
];

export const experience = [
  {
    id: 1,
    year: "2022/01 ~ 2022/07",
    name: "Governance Analyst Team",
    place: "Daimler Trucks Asia",
  },
  {
    id: 2,
    year: "2019/12 ~ 2020/06",
    name: "IT support",
    place: "Speed Money Transfer Japan",
  },
  {
    id: 3,
    year: "2018/08 ~ 2018/09",
    name: "Teaching Assistant",
    place: "Temple University Japan",
  },
];

export const workingSkills = [
  {
    id: 1,
    percentage: "80",
    color: "#F47340",
    skillName: "HTML",
    svgimage: html,
  },
  {
    id: 2,
    percentage: "80",
    color: "#1C6DD0",
    skillName: "CSS",
    svgimage: css,
  },
  {
    id: 3,
    percentage: "60",
    color: "#FFF338",
    skillName: "Java Script",
    svgimage: JavaScript,
  },
  {
    id: 4,
    percentage: "70",
    color: "#5ED3F3",
    skillName: "React",
    svgimage: REACT,
  },
  {
    id: 5,
    percentage: "70",
    color: "#08d565",
    skillName: "Python",
    svgimage: python,
  },
  {
    id: 6,
    percentage: "85",
    color: "#FFFFDE",
    skillName: "Adobe PS",
    svgimage: photoshop,
  },
  {
    id: 7,
    percentage: "60",
    color: "#F79500",
    skillName: "Adobe Ai",
    svgimage: illustrator,
  },
  {
    id: 8,
    percentage: "60",
    color: "#FFC107",
    skillName: "Power Bi",
    svgimage: powerbi,
  },
];
