import uiux from "../assets/services/uiux.svg";
import graphic from "../assets/services/graphic.svg";
import photo from "../assets/services/photo.svg";
import data from "../assets/services/graph.svg";
import web from "../assets/services/webdevelopment.svg";
import manag from "../assets/services/marketing.svg";

const service = [
  {
    id: 1,
    num: "01",
    title: "Ui/Ux Design",
    svgimg: uiux,
    des: "Creating intuitive interfaces that facilitate the development of responsive layouts is where my expertise lies.",
  },
  {
    id: 2,
    num: "02",
    title: "Graphic Design",
    svgimg: graphic,
    des: "I am able to produce designs that are tailored to the requirements of my customers thanks to the assistance of Adobe's software.",
  },
  {
    id: 3,
    num: "03",
    title: "Photography",
    svgimg: photo,
    des: "I take images as well as aerial views in order to fulfill the requirements set out by my clients.",
  },
  {
    id: 4,
    num: "04",
    title: "Data Analyst",
    svgimg: data,
    des: "The use of Power BI's dashboards has made it possible for me to generate, organize, and examine extensive volumes of data.",
  },
  {
    id: 5,
    num: "05",
    title: "Web Development",
    svgimg: web,
    des: "Develop websites that are not only easy to use but also take into account the tastes of the audience you are trying to reach.",
  },
  {
    id: 6,
    num: "06",
    title: "Management",
    svgimg: manag,
    des: "Since, I have extensive expertise in advertising and marketing, I am in an excellent position to help you in expanding your business.",
  },
];

export default service;
