import "./services.scss";
import Servicecard from "../pages/servicecards/Servicecard";
import Resume from "../pages/resume/Resume";
import Skills from "../pages/skills/Skills";

const Services = () => {
  return (
    <section className="bishal_section" id="services">
      <div className="container-resume">
        <div className="resume">
          <div className="services-header">
            <h2>What i do!</h2>
            <h1>My Services</h1>
            <div className="line">
              <div className="lines"></div>
            </div>
          </div>

          {/* <!---------------------- My Services ---------------------------> */}
          <Servicecard />
        </div>
      </div>
      {/* <!---------------------- My Resume ---------------------------> */}
      <Resume />

      {/* <!---------------------- My WorkingSkills ---------------------------> */}
      <Skills />
    </section>
  );
};

export default Services;
