import "./skills.scss";
import {useEffect} from "react";
import {activeSkillProgress} from "../../../utilities";
import {workingSkills} from "../../../data/education";
const Skills = () => {
  useEffect(() => {
    window.addEventListener("scroll", activeSkillProgress);
  }, []);

  return (
    <section id="skills">
      <div className="container-1440">
        <div className="container-skill">
          <div className="dodo_progress">
            <h1>Working Skills</h1>

            <ul>
              <li>
                <div className="list_inner">
                  {workingSkills.map(
                    ({id, percentage, color, skillName, svgimage}) => {
                      return (
                        <div
                          className="skillsInner"
                          data-value={percentage}
                          data-color={color}
                          key={id}
                        >
                          <div className="percent">
                            <div className="skills-title">
                              <img src={svgimage} alt="" />
                              <h2>{skillName}</h2>
                            </div>
                            <span className="number">{percentage}%</span>
                          </div>
                          <div className="background">
                            <div className="bar">
                              <div className="bar_in" />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </li>
            </ul>
          </div>

          <div className="knowledgs-container">
            <h1>Knowledges</h1>
            <div className="knowledge">
              <button>Multitasking</button>
              <button>Problem Solving</button>
              <button>Flexibility</button>
              <button>Teamwork</button>
              <button>Creativity</button>
              <button>Responsibility</button>
              <button>Design</button>
              <button>Mathematics</button>
              <button>Marketing</button>
              <button>Customer Service</button>
              <button>Positivity</button>
              <button>Data Analysis</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
